
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import * as d3 from "d3";
import EventBus from "../eventBus";
import { utils } from '../services/utils';


const color = d3.scaleOrdinal(d3.schemeCategory10);

@Component
export default class Wheel extends Vue {

  padding = {top:0, right:0, bottom:0, left:0}
  w = 0
  h = 0
  r = 0
  INNER_RAD = 0
  inProgress = false


  data = [{}]
  rotation = 0
  oldrotation = 0
  picked = 100000
  oldpick:any = []
  vis:any
  container:any

  pickedObject = {}
  cards=[]
  count=4

  theSize = 4
  max = 24
  min = 12
  windowWidth
  windowHeight
  maxWidth = 766
  contW = 480
  offsetLeft = 0

  @Prop({default: 24})
  minSlice = 24

  constructor() {
    super();
  }
  created(){
    this.windowWidth = document.documentElement.clientWidth;
    this.windowHeight = document.documentElement.clientHeight;
    this.loadConfig()
  }

  noData = false

  mounted() {
    EventBus.$on("new-shop-data", shopData => {
      console.log("received new shop data")
      utils.shuffleArray(shopData)
      shopData = shopData.slice(0,this.max)
      this.data = shopData
      if(this.data.length<1){
        console.log("no wheel data")
        this.noData = true
        EventBus.$emit('noData', true)
        return
      }
      if(this.data.length < this.minSlice){
        //this.enrichRandomData()
        this.enrichReplicateData()
      }
      this.noData = false
      this.draw()
    });
    EventBus.$on("spin", direction => {
      console.log("spinning", direction)
      this.spin()
    });
    EventBus.$on("reset", instruction => {
      console.log("reset", instruction)
      this.reset()
    });

  }
  enrichReplicateData(){
    let len = this.minSlice - this.data.length
    let additionalSlices = []
    let item:any={}
    for (let index = 0; index < len; index++) {
      item = this.data[Math.floor(Math.random()*this.data.length)]
      this.data.push(item)
    }
    utils.shuffleArray(this.data)
    //return additionalEmptySlices
  }

  enrichRandomData(){
    let len = this.minSlice - this.data.length
    let additionalEmptySlices = []
    let item:any={}
    for (let index = 0; index < len; index++) {
      item = {
          val:1,
          label:"Empty",
          slug:"",
          image:"https://www.sunwaypyramid.com/static/shops/5dee7a75a3f245f76d41a889760ed05c/w380-crop.jpg",
          meta:{}
      }
     this.data.push(item)
    }
    utils.shuffleArray(this.data)
    //return additionalEmptySlices
  }

  loadConfig(){
    this.theSize = 6
    this.padding = {top:0, right:0, bottom:0, left:0}
    
    if(this.windowWidth<this.maxWidth){
      this.theSize = 3
      this.INNER_RAD = 40
      this.w = this.maxWidth - this.padding.left - this.padding.right
      this.h = this.maxWidth - this.padding.top  - this.padding.bottom
      this.max = 48
      this.offsetLeft = 0//(this.windowWidth - this.w)/2

    }
    if(this.windowWidth>=this.maxWidth){
      this.INNER_RAD = 80
      this.w = this.maxWidth - this.padding.left - this.padding.right
      this.h = this.maxWidth - this.padding.top  - this.padding.bottom
      this.max = 48
      this.offsetLeft = 0
    }
    //this.max = 50
    this.r = (Math.min(this.w, this.h)/2)-20
    
  }
  draw(){
    this.INNER_RAD = 20
    this.maxWidth = (this.data.length/2) * 76.6
    this.offsetLeft = ((this.contW-this.maxWidth)/2)
    console.log("this.windowWidth",this.windowWidth, this.maxWidth, this.offsetLeft)
    //1378 459.59999999999997 153.20000000000002
    //360 459.59999999999997 153.20000000000002
    if(this.windowWidth<this.contW){
      console.log("special cond this.windowWidth<this.contW")
      this.offsetLeft = this.offsetLeft - ((this.contW-this.windowWidth)/2)
    }
    if(this.maxWidth<this.contW && this.windowWidth>=this.contW){
      this.offsetLeft = 0
    }
    
    this.w = this.maxWidth - this.padding.left - this.padding.right
    this.h = this.maxWidth - this.padding.top  - this.padding.bottom
    this.max = 48
    
    this.r = (this.w/2)-20
    this.INNER_RAD = this.r-64-20-120





    let imageW = 64
    //let adjustedSize = (this.data.length/12)
    var ps = 360/this.data.length //perSlice
    //console.log("stats", this.data.length, this.max, imageW, 360/this.data.length, adjustedSize, ps)
    //imageW = imageW/adjustedSize
    //console.log("adjusted imageW orig/adj", this.w/10, imageW)
    // imageW = adjustImageBasedOnDataCount
    
    console.log("offsetLeft",this.offsetLeft, this.w, this.windowWidth)
    //offsetLeft = (-1)*offsetLeft
    const wheel = d3.select(this.$el)
      .style("width", (this.w) + "px")
      //.style("position", "absolute")
      //.style("height", (this.h) + "px")
      //.style("bottom", "-"+(this.h/2) + "px")
      
    wheel.append("img").attr("src","images/cream-cone-top.png").style("display","block").style("margin","auto").style("height","64px").style("padding-bottom","3px").style("margin-bottom","-20px")
    const svg = wheel.append("svg");
    const g = svg
      .data([this.data])
      .attr("width", this.w + this.padding.left + this.padding.right)
      .attr("height", (this.h) + this.padding.top + this.padding.bottom)
      //.style("position", "absolute")
      //.style("left", ""+offsetLeft + "px")
      .style("transform", "translateX("+this.offsetLeft + "px)")
      .style("display","block")
      //.style("margin","auto")
      
      
    this.container = svg.append("g")
      .attr("class", "chartholder")
      .attr("transform", "translate(" + (this.w/2 + this.padding.left) + "," + (this.h/2 + this.padding.top) + ")");

    this.vis = this.container
      .append("g");
    
    var pie = d3.pie().sort(null).value((d,i)=>{return this.data[i]['val'];});
    var arc = d3.arc()
      .innerRadius(this.INNER_RAD)
      .outerRadius(this.r)
      .cornerRadius(12)
      
    
    var arcWhite = d3.arc()
      .innerRadius((this.r-imageW)-20)
      .outerRadius(this.r)
      .cornerRadius(8)
    
    //console.log("arc",arc)
    var arcs = this.vis.selectAll("g.slice")
            .data(pie)
            .enter()
            .append("g")
            .attr("class", "slice");
    
    //arcs.append('rect').attr("width",300).attr("height",300)
    
    arcs.append("path")
        .attr("class","col")
       // .attr("fill", (d, i)=>{ return color(i); })//
        .attr("stroke", "#FBF8F2")
        .attr("stroke-width", "5")
        .attr("stroke-linecap", "round")
        //.attr("stroke-dasharray","5")
        .attr("d", (d)=>{ return arc(d)})
        
    arcs.append("path")
        .attr("class","whit")
        .attr("stroke", "#FBF8F2")
        .attr("stroke-width", "5")
        .attr("stroke-linecap", "round")
        //.attr("stroke-dasharray","5")
        .attr("d", (d)=>{ return arcWhite(d)})


    
    console.log("ps",ps)
    // arcs
    //   .append("rect").attr("width",64).attr("height",64).attr("fill","white").attr("stroke","black")
    //   .attr("transform", (d)=>{
    //     d.innerRadius = 0;
    //     d.outerRadius = this.r - this.w/10;
    //     d.angle = (d.startAngle + d.endAngle)/2;
    //     let ang = (d.angle * 180 / Math.PI - 90) 
    //     return "rotate("+ang+")translate(" + (d.outerRadius) +")"
    //   })



    let imageOffset = -30//((ps*(-1))/2) + imageW/2//((this.r/this.data.length)*2)/2 //
    if(this.offsetLeft!=0){
      //imageOffset = -20//((ps*(-1))) + imageW/2
     //imageOffset = ((this.r/this.data.length)*2)-20 //((ps*(-1))/2) + imageW/2
    }

    console.log("imageW/ps", imageW, ps, this.w/10, this.maxWidth)
    arcs
      .append("image")
        .attr("transform", (d)=>{
        d.innerRadius = 0;
        d.outerRadius = (this.r - imageW) - 10;
        d.angle = ((d.startAngle + d.endAngle)/2);
        let ang = ((d.angle * 180 / Math.PI) - 90) 
        //console.log("ang",ang)
        return "rotate("+ang+")translate(" + (d.outerRadius) +")";
    })
    .attr("xlink:href", (d,i)=>{return this.data[i]['image']})
    .attr("width",imageW)
    .attr("y",imageOffset)
    .attr("height",imageW);

    // add the text
    //<rect id="myRect" x="50" y="50" rx="10" ry="10" width="300" fill="#4682b4" stroke="black" stroke-width="5" opacity=".5" height="50"></rect>
    // arcs.append("rect").attr("transform", (d)=>{
    //     d.innerRadius = 0;
    //     d.outerRadius = r-100;
    //     d.angle = (d.startAngle + d.endAngle)/2;
    //     let ang = (d.angle * 180 / Math.PI - 90)
    //     return "rotate("+ang+")translate(" + (d.outerRadius) +")";
    // })

    var xBuffer=50;
    var yBuffer=150;
    var lineLength=400;
    arcs.append("text").attr("transform", (d)=>{
        d.innerRadius = 15;
        d.outerRadius = (this.r-imageW)-30;
        d.angle = (d.startAngle + d.endAngle)/2;
        let ang = (d.angle * 180 / Math.PI - 89.5)
        return "rotate("+ang+")translate(" + (d.outerRadius) +")";
    })
    
    .attr("text-anchor", "end")
    //.attr("writing-mode", "tb")
    //.attr("x",xBuffer+(lineLength/2))
    .attr("font-size",10)
    .text( (d, i)=>{
        return this.titleCase(this.data[i]['label']);
    });
    
    setTimeout(()=>{
   // this.spin()
    },2000)
  }

  titleCase(str){
   str = str.toLowerCase().split(' ');
   let final:any = [ ];
  for(let word of str){
    final.push(word.charAt(0).toUpperCase() + word.slice(1));
  }

  let finRes = final.join(' ')
  //console.log("finRes", finRes.length)
  if(finRes.length>15){
    finRes = finRes.substr(0,15) + "…"
  }
  return finRes
}

  reset(){
    
  }

  spin(){
    EventBus.$emit('spinning', true)
    if(this.inProgress == true){
      console.log("Blocking... spin in progress...")
      return
    }
    this.inProgress = true
    let HOWLONG = 4500 + (Math.random()*500)

    let ps       = 360/this.data.length
    let pieslice = Math.round(HOWLONG/this.data.length)
    let rng      = Math.floor((Math.random() * HOWLONG) + 3600);
          
      this.rotation = (Math.round(rng / ps) * ps);
      //console.log("this.rotation",this.rotation, rng, ps)

      this.picked = Math.round(this.data.length - (this.rotation % 360)/ps);
      //console.log("this.picked, this.data.length",this.picked, this.data.length, this.rotation % 360, (this.rotation % 360)/ps)
      this.picked = this.picked >= this.data.length ? (this.picked % this.data.length) : this.picked;


      //console.log("this.picked",this.picked) 
      this.pickedObject = this.data[this.picked]
      this.oldpick.push(this.picked);

      this.rotation += 0 - Math.round(ps/2);
      //console.log("this.rotation",this.rotation)
      
      this.vis.transition()
          .duration(HOWLONG)
          .attrTween("transform", this.rotTween)
          .on("end", ()=>{

              //mark question as seen
              EventBus.$emit('pickedShop', this.pickedObject)

              d3.select(".slice:nth-child(" + (this.picked + 1) + ") path")
                  .attr("fill", "#111");

              this.oldrotation = this.rotation;
              this.inProgress = false
              EventBus.$emit('spinning', false)
              
          });
  }

  rotTween(to) {
    console.log("this.oldrotation % 360, this.rotation",this.oldrotation,this.oldrotation % 360, this.rotation)
    var i = d3.interpolate(this.oldrotation % 360, this.rotation);
    return (t)=>{
      return "rotate(" + i(t) + ")";
    };
  }

  populateRand(){
    this.count = Math.floor(Math.random()*20) + 5
    let lenn = this.data.length
    console.log("count,lenn",this.count,lenn)
    let dt:any = []
    var ps = 360/this.count
    console.log("degree offset per card",ps)
    for(let i=0; i++, i<=this.count;){
      let pos = Math.floor(Math.random()*lenn)
      let itm:{} = this.data[pos]
      itm['label'] = i +". "+itm['label'] 
      itm['rot'] = ps*i 
      dt.push(itm)
    }
    this.data = dt
    console.log("this.data",this.data)
  }

  // loadData(){
  //   this.data = [
  //       {val:1, "label":"Kenny Rogers",  "slug":"kenny-rogers-roasters",  "image":"https://assets.sunwaypyramid.com/shops/c2cd4e6883278d87419148333e35b10a/w180-crop.png"},
  //       {val:1, "label":"798 Shabu Shabu",  "slug":"798-shabu-shabu",  "image":"https://assets.sunwaypyramid.com/shops/ebab3863178b04950f340750709eddd8/w180-crop.png"},
  //       {val:1, "label":"An Viet",  "slug":"an-viet",  "image":"https://assets.sunwaypyramid.com/shops/dfcd23465d39a9254e899ba26d7c325a/w180-crop.png"},
  //       {val:1, "label":"Ah Cheng Laksa",  "slug":"ah-cheng-laksa",  "image":"https://assets.sunwaypyramid.com/shops/71c86206559f185ecf9dd7cc78c4e60f/w180-crop.png"},
  //       {val:1, "label":"Baskin Robbins",  "slug":"baskin-robbins",  "image":"https://assets.sunwaypyramid.com/shops/146689969df7995afda4db84e746d879/w180-crop.png"}
  //   ];
  // }
}
