
import { Component, Vue, Emit } from "vue-property-decorator";
import { systemSrv } from "./../services/system";
import store from "./../store";
import Header from "../components/common/Header.vue";
import Footer from "../components/common/Footer.vue";
import router from "@/router";
import { cacheSrv } from "./../services/cache";
import { notiSrv } from "./../services/noti";
import { httpService } from "./../services/http";

@Component({
  components: {
    Header,
    Footer
  }
})
export default class Welcome extends Vue {
  constructor() {
    super();
  }

  goStart() {
    router.push("start");
  }
}
