
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Header extends Vue {
  title

  created() {
    
  }
}
