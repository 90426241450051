class Utils {
  spinAudio
  winAudio
  failAudio

  constructor() {
    console.log("new instance of Utils Service")

    this.initialiseAudio()
  }

  initialiseAudio() {
    let url = "https://makanwheel.sunwaypyramid.com/"

    //var audio = new Audio(url + "sounds/" + filename);

    this.spinAudio = new Audio(url + "sounds/" + "wheel.mp3")
    this.winAudio = new Audio(url + "sounds/" + "win.mp3")
    this.failAudio = new Audio(url + "sounds/" + "empty.mp3")

    console.log("Audi init")
  }
  uuid() {
    return (this.S4() + this.S4() + "-" + this.S4() + "-4" + this.S4().substr(0, 3) + "-" + this.S4() + "-" + this.S4() + this.S4() + this.S4()).toLowerCase()
  }
  S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
  }

  playAudio(filename) {
    // let url = "https://makanwheel.sunwaypyramid.com/";
    // var audio = new Audio(url + "sounds/" + filename);
    //
    console.log("play sound", filename)
    try {
      if (filename == "win.mp3") {
        this.winAudio.play()
      }
      if (filename == "empty.mp3") {
        this.failAudio.play()
      }
      if (filename == "wheel.mp3") {
        this.spinAudio.play()
      }
    } catch (error) {
      console.log("Failed to play audio", error)
    }
  }
}

export const utils = new Utils()
