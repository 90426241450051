import axios from 'axios'
import {cacheSrv} from './cache'
import store from './../store'

class HttpService{

  fractalsWebsiteApi = store.getters.config.fractalsWebsiteApi;
  
  localStore:any

  constructor(){
    console.log("new instance of HttpService")
    if (window.localStorage) {
      try {
        this.localStore = window.localStorage
      } catch(e) {
        console.log("Local storage not supported on this browser. May need to use cookies")
      }
    }
  }

  async getShopsWithORFilters(dietaryNeeds, preferences){
    const cacheKey = "sunwaypyramid-campaigns"
    try {
      let cachedVersion = cacheSrv.cached(cacheKey)
      if (cachedVersion) {
        //return cachedVersion
      }
      //https://api.sunwaypyramid.com/__system_/directory-live/v1/public/shops/lookup-category-paged/678?offset=0&limit=12&subs=Fast%20Food
      let payload = {
        category:665, 
        dietaryNeeds:dietaryNeeds,
        preferences:preferences
      }
      console.log("payload",payload)
      let result = await axios.post(`${this.fractalsWebsiteApi}/shops/lookup-filtered-subcats-paged?size=250`, payload)
      if (result.data && result.data) {
        cacheSrv.cacheEx(cacheKey, result.data, 2)//just 2 seconds to avoid HIT after HIT, but just get is from Server most of the time
        return result.data
      }
      return null;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
      }
      
    }
  }
  


  async getShops(subCategories){
    const cacheKey = "sunwaypyramid-campaigns"
    try {
      let cachedVersion = cacheSrv.cached(cacheKey)
      if (cachedVersion) {
        //return cachedVersion
      }
      //https://api.sunwaypyramid.com/__system_/directory-live/v1/public/shops/lookup-category-paged/678?offset=0&limit=12&subs=Fast%20Food
      let payload = {
        category:665, 
        subs:subCategories
      }
      console.log("payload",payload)
      let result = await axios.post(`${this.fractalsWebsiteApi}/shops/lookup-category-subcategory-paged?size=250`, payload)
      if (result.data && result.data) {
        cacheSrv.cacheEx(cacheKey, result.data, 2)//just 2 seconds to avoid HIT after HIT, but just get is from Server most of the time
        return result.data
      }
      return null;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
      }
    }
  }
  
}

// ###
// POST http://localhost:4003/__system_/directory-live/v1/public/shops/lookup-category-subcategory-paged HTTP/1.1
// content-type: application/json

// {
//   "category":"678",
//   "subs":[
//     "Japanese",
//     "Halal"
//   ]
// }


export const httpService = new HttpService()
