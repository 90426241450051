
import {Component, Vue, Emit} from "vue-property-decorator"
import {systemSrv} from "./../services/system"
import store from "./../store"
import Header from "../components/common/Header.vue"
import Footer from "../components/common/Footer.vue"
import Wheel from "../components/Wheel.vue"
import router from "@/router"
import {cacheSrv} from "./../services/cache"
import {notiSrv} from "./../services/noti"
import {httpService} from "./../services/http"
import EventBus from "../eventBus"

import * as d3 from "d3"
import {utils} from "./../services/utils"

@Component({
  components: {
    Header,
    Footer,
    Wheel,
  },
})
export default class Welcome extends Vue {
  selected = {}
  subCatTitle: any = ""
  pref: any = []
  shops: any = []
  pickedObject = {}
  noData = false
  emptyShop = null
  suprise = false
  minSlice = 24
  spinning = false

  constructor() {
    super()
  }

  shopModal
  mounted() {
    // (this.$refs["_username"] as HTMLElement).focus()
    this.shopModal = this.$refs["shopModal"] as HTMLElement //.on("hidden.bs.modal", this.onHidden)
    console.log("this.shopModal", this.shopModal)

    window.addEventListener("keyup", (event) => {
      if (event.keyCode === 27) {
        //this.pickedObject = {}
      }
    })
    window.addEventListener("mouseup", (event) => {
      setTimeout(() => {
        //this.pickedObject = {}
      }, 1000)
    })
  }

  modalWin(onOrOff) {
    const el = document.body
    if (onOrOff == true) {
      el.classList.add("modal-open")
    } else {
      el.classList.remove("modal-open")
    }
  }

  touchHandler(direction) {
    console.log("touchHandler::direction", direction)
    if (direction == "right" || direction == "left") {
      EventBus.$emit("spin", direction)
    }
  }

  playAudio(filename) {
    let url = "https://makanwheel.sunwaypyramid.com/"
    var audio = new Audio(url + "sounds/" + filename)
    try {
      audio.play()
    } catch (error) {
      console.log("Failed to play spin audio", error)
    }
  }
  playPreloadedAudio(filename) {
    utils.playAudio(filename)
  }

  destroy() {
    document.body.className = ""
    console.log("DESTROYED")
  }

  created() {
    document.body.className = "fixed"
    this.minSlice = Number(this.$route.query["minSlice"])
    this.suprise = Boolean(this.$route.query["suprise"])
    console.log("this.suprise", this.suprise)
    this.subCatTitle = this.$route.query["subCatTitle"]
    this.pref = this.$route.query["pref"]

    if (typeof this.pref === "string") {
      this.pref = [this.pref]
    }
    console.log("SPIN wheel processing for cat, subcat, prefs", this.subCatTitle, this.pref)

    this.$ga.event({
      eventCategory: "filter",
      eventAction: "selected",
      eventLabel: "dietary_needs",
      eventValue: this.subCatTitle ? this.subCatTitle : "Any",
    })
    if (this.pref) {
      this.pref.forEach((i) => {
        this.$ga.event({
          eventCategory: "filter",
          eventAction: "selected",
          eventLabel: "cousine",
          eventValue: i,
        })
      })
    }

    if (this.suprise) {
      this.$ga.event({
        eventCategory: "filter",
        eventAction: "selected",
        eventLabel: "cousine",
        eventValue: "Suprise Me",
      })
    }

    this.loadData()

    EventBus.$on("pickedShop", (pickedShop) => {
      console.log("picked SHOP", pickedShop)
      this.emptyShop = null
      if (pickedShop.slug == "") {
        this.playPreloadedAudio("empty.mp3")
        //EMPTY
        //this.emptyShop = pickedShop
        this.modalWin(true)
        this.pickedObject = this.pickRandomEmpty(pickedShop)
      } else {
        this.playPreloadedAudio("win.mp3")
        this.modalWin(true)
        this.pickedObject = pickedShop
      }
    })
    EventBus.$on("noData", (noData) => {
      console.log("noData", noData)
      this.emptyShop = null
      this.noData = noData
    })

    EventBus.$on("spinning", (spinning) => {
      this.spinning = spinning
      if (this.spinning == true) {
        this.playPreloadedAudio("wheel.mp3")
      }
    })
  }

  pickRandomEmpty(pickedShop) {
    let empties = ["Oops! Give me another spin!", "Sorry, Try Again", "Nothing here", "Better luck next time"]
    pickedShop.label = empties[Math.floor(Math.random() * empties.length)]
    return pickedShop
  }
  closeModal() {
    this.modalWin(false)
    this.pickedObject = {}
  }

  onHidden() {
    console.log("Hidden now")
  }

  viewPromo(obj) {
    console.log("viewPromo")
  }
  viewShop(obj) {
    console.log("viewShop")
  }

  async loadData() {
    console.log("this.pref", this.pref)
    if (!this.pref) {
      this.pref = []
    }
    let addWesternAndFF = false
    let preferences = this.pref.map((i) => {
      if (i == "Asian & Japanese") {
        //Western & Fast Food
        addWesternAndFF = true
        return "Asian"
      } else {
        return i
      }
    })
    if (addWesternAndFF) {
      //dietaryNeeds.push("Western")
      preferences.push("Japanese")
    }
    // if(this.subCatTitle){
    //   subs.push(this.subCatTitle)
    // }

    let dietaryNeeds: any = []
    if (this.subCatTitle && this.subCatTitle == "Muslim Friendly") {
      dietaryNeeds.push("Halal")
      dietaryNeeds.push("Pork Free")
    }
    let shops = await httpService.getShopsWithORFilters(dietaryNeeds, preferences)
    if (shops) {
      this.shops = shops.docs.map((itm) => {
        return {
          val: 1,
          label: itm.title,
          slug: itm.slug,
          //image: itm.logo._meta.thumbsUrl+'w320crop'+itm.logo._meta.ext,
          image: "https://www.sunwaypyramid.com/static/shops/" + itm.logo.local.replace(itm.logo._meta.ext, "/w180-crop" + itm.logo._meta.ext),
          meta: itm,
        }
      })
      EventBus.$emit("new-shop-data", this.shops)
      console.log("Shops loading from external URL complete")

      if (this.shops && this.shops.length > 0) {
        if (this.suprise) {
          console.log("SPINNNIINNG")
          setTimeout(() => {
            EventBus.$emit("spin", "left")
          }, 388)
        } else {
          setTimeout(() => {
            EventBus.$emit("spin", "left")
          }, 388)
        }
      }
    }
  }

  loadWheel() {}

  spin() {
    this.pickedObject = {}
    EventBus.$emit("spin", "left")
  }
  goback() {
    if (!this.spinning) {
      document.body.className = ""
      let qry: {} = {subCatTitle: this.subCatTitle, pref: this.pref}
      console.log("qry", qry)
      router.push({name: "preferences", query: qry})
    }
  }
  goStart() {
    if (!this.spinning) {
      document.body.className = ""
      router.push("start")
    }
  }
  makeNewSelect() {
    document.body.className = ""
    let qry: {} = {subCatTitle: this.subCatTitle}
    console.log("qry", qry)
    router.push({name: "preferences", query: qry})
  }
}
