
import { Component, Vue, Emit } from "vue-property-decorator";
import { systemSrv } from "./../services/system";
import store from "./../store";
import Header from "../components/common/Header.vue";
import Footer from "../components/common/Footer.vue";
import router from "@/router";
import { cacheSrv } from "./../services/cache";
import { notiSrv } from "./../services/noti";
import { httpService } from "./../services/http";

@Component({
  components: {
    Header,
    Footer
  }
})
export default class Welcome extends Vue {
  subtitles = [
    "Craving for any specific cuisine? Take your pick from the preferences below!",
    "Have some cravings in mind? Take your pick from the cuisine below!",
    "Feeling hungry for a type of cuisine? Choose from the options below!"
  ];
  subtitle = this.subtitles[Math.floor(Math.random() * this.subtitles.length)];

  selected = {};
  subCatTitle: any = "";
  isKidsFriendly = false;
  KIDS_FRIENDLY = "Kid-Friendly Food";
  opts = [
    { label: "Snack", img: "snack_colour.png" },
    { label: "Local", img: "local_colour.png" },
    { label: "Western", img: "western_colour.png" },
    { label: "Fast Food", img: "fastfood_colour.png" },
    { label: "Asian & Japanese", img: "asian_colour.png" },
    { label: "Café", img: "cafe_colour.png" },
  ];
  
    //{ label: "Japanese & Asian", img: "japanese_colour.png" }
  //{ label: "Fast food", img: "fastfood_colour.png" },
  //{label:"Mediterranean",img:"flavour-meditareanean.png"},

  //,{label:"suprise me",img:"flavour-supriseme.png"}
  constructor() {
    super();
  }

  pref;
  created() {
    document.body.className = '';
    this.subCatTitle = this.$route.query["subCatTitle"];
    this.pref = this.$route.query["pref"];
    if (typeof this.pref === "string") {
      this.pref = [this.pref];
    }
    if (this.pref) {
      this.pref.forEach(i => {
        this.selectOption(i);
        if (i == this.KIDS_FRIENDLY) {
          this.isKidsFriendly = true;
        }
      });
    }

    console.log("this.subcars, pref", this.subCatTitle, this.pref);
  }
  setKidsFriendly() {
    this.isKidsFriendly = !this.isKidsFriendly;
    this.selectOption(this.KIDS_FRIENDLY);
  }
  selectOption(opt) {
    //console.log("opt", JSON.parse(JSON.stringify(opt)));

    console.log("opt", opt, this.selected[opt]);
    if (this.selected[opt] == 1) {
      Vue.delete(this.selected, opt);
    } else {
      //this.selected[opt] = 1
      this.$set(this.selected, opt, 1);
    }
    console.log("this.selected", JSON.parse(JSON.stringify(this.selected)));
  }
  goback() {
    router.push("start");
  }

  suprise() {
    let qry: {} = { pref: [], subCatTitle: this.subCatTitle, suprise: true };
    console.log("this.selected before spin", this.selected);
    this.selected = {};
    router.push({ name: "spin", query: qry });
  }
  goSpin() {
    let qry: {} = { pref: [], subCatTitle: this.subCatTitle };
    console.log("this.selected before spin", this.selected);
    Object.keys(this.selected).forEach(itm => {
      qry["pref"].push(itm);
    });
    router.push({ name: "spin", query: qry });
  }
}
