
import { Component, Vue, Emit } from "vue-property-decorator";
import { systemSrv } from "./../services/system";
import store from "./../store";
import Header from "../components/common/Header.vue";
import Footer from "../components/common/Footer.vue";
import router from "@/router";
import { cacheSrv } from "./../services/cache";
import { notiSrv } from "./../services/noti";
import { httpService } from "./../services/http";

@Component({
  components: {
    Header,
    Footer
  }
})
export default class Welcome extends Vue {
  selected: any = [];

  subtitles = [
    "Any dietary preferences? Let us know!",
    "Have some dietary preferences? Choose below!"
  ];
  subtitle = this.subtitles[Math.floor(Math.random() * this.subtitles.length)];

  constructor() {
    super();
  }

  created(){
    document.body.className = '';
  }

  selectOption(subCatTitle) {
    console.log("selected", subCatTitle);
    if (subCatTitle == this.selected) {
      this.selected = "";
    } else {
      this.selected = subCatTitle;
    }
  }
  goPreferences() {
    let qry: {} = { subCatTitle: this.selected };
    router.push({ name: "preferences", query: qry });
  }
}
